<template>
  <div class="content">
    <ActionFlow
    select_status="done"
    post_status="done"
    complete_status="done"
    />
    <div class="reception-msg smari-no">
      スマリ受付番号は{{ box_reception.box_no }}です。
    </div>
    <article class="message is-success is-small">
        <div class="message-body">
          商品発送受付完了メールをご確認ください。
        </div>
      </article>
  </div>
</template>

<script>
import ActionFlow from "../components/elements/ActionFlow.vue"

export default {
  name: "StoreComplete",
  components: {
    ActionFlow,
  },
  computed: {
    box_reception() {
      return this.$store.state.box_reception
    },
  },
}
</script>

<style scoped>
.reception-msg {
  text-align: center;
  font-weight: bold;
}
.smari-no {
  margin-bottom: 40px;
}
.email-notify {
  padding-top: 50px;
}
</style>
